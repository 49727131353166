<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Warranty"
              url="/pages/agreements/warranty/manage"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadWarranty()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :cell-template="item.template"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import warrantyApi from '@api/contract_headers';
import { queryParameters } from '@/schema';

export default {
  name: 'WarrantyPage',
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    totalCount: 0,
    page: 0,
    size: 0,
    selectedData: [],
    dataColumns: [
      { caption: 'Contract Reference', field: 'refnum' },
      // { caption: 'Company ID', field: 'company_id' },
      { caption: 'Company Name', field: 'company_name' },
      { caption: 'Contract Type', field: 'contract_type' },
      { caption: 'Status', field: 'status' },
      { caption: 'Start', field: 'start_date' },
      { caption: 'End', field: 'end_date' },
    ],
  }),
  watch: {
    page(v) {
      this.loadWarranty();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadWarranty();
      }
    },
  },
  mounted() {
    this.loadWarranty();
  },
  methods: {
    loadWarranty() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      warrantyApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const { data } = event;

      if (data) {
        const url = `/pages/agreements/warranty/view?id=${data.id}`;
        this.$router.push(url);
      }
    },
    onUpdate(event) {
      const data = event.data;
      warrantyApi
        .update(data)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
          }
        })
        .catch((err) => {
          if (err) {
            this.toastConfig('', 'error');
          }
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      warrantyApi
        .delete(event)
        .then(() => {
          this.loadWarranty();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
